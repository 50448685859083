<template>
  <div class="seo-links">
    <ul>
      <li v-for="(link, idx) in links" :key="`link_${idx}`">
        <NuxtLink class="text-link" :to="link.url" :title="link.title">
          {{ link.text }} Jobs
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script>
import { links } from '@/lib/util/seo.js'

export default {
  name: 'SeoLinks',
  data() {
    return { links: links(this.$sentry) }
  }
}
</script>

<style lang="scss" scoped>
.seo-links {
  width: 100%;
  background-color: white;
  border: 8px solid color(light-shadow);
  border-radius: spacing(4);
  text-align: center;
  padding: spacing(4) 0;
  margin: 0 spacing(2) spacing(3);

  @include breakpoint(medium) {
    border: 12px solid color(light-shadow);
    padding: spacing(5) 0;
    margin: 0 spacing(5) spacing(5);
  }

  @include breakpoint(large) {
    max-width: spacing(160);
    padding: spacing(10) spacing(10) spacing(8);
    margin: 0 spacing(4) spacing(12);
  }
}

.text-link {
  color: color(black, base);
}
</style>
