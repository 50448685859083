<template>
  <div class="incentive">
    <h2 class="incentive__title">
      <span>{{ $t('pages.index.incentive.title') }}</span>
      <span class="incentive__title--background">{{
        $t('pages.index.incentive.title2')
      }}</span>
      <span>{{ $t('pages.index.incentive.title3') }}</span>
    </h2>
    <p class="incentive__text">
      {{ $t('pages.index.incentive.text') }}
    </p>
    <div class="benefits">
      <NuxtLink class="benefit" :to="link">
        <icon name="personality" class="benefit-icon" />
        <h5 class="benefit-title">
          {{ $t('pages.index.incentive.benefits.personality.title') }}
        </h5>
        <p class="benefit-text">
          {{ $t('pages.index.incentive.benefits.personality.text') }}
        </p>
      </NuxtLink>
      <NuxtLink class="benefit" :to="link">
        <icon name="target-jobs" class="benefit-icon" />
        <h5 class="benefit-title">
          {{ $t('pages.index.incentive.benefits.jobs.title') }}
        </h5>
        <p class="benefit-text">
          {{ $t('pages.index.incentive.benefits.jobs.text') }}
        </p>
      </NuxtLink>
      <NuxtLink class="benefit" :to="link">
        <icon name="events" class="benefit-icon" />
        <h5 class="benefit-title">
          {{ $t('pages.index.incentive.benefits.events.title') }}
        </h5>
        <p class="benefit-text">
          {{ $t('pages.index.incentive.benefits.events.text') }}
        </p>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Incentive',
  computed: {
    ...mapGetters('user', ['email']),
    authenticated() {
      return this.email !== ''
    },
    link() {
      if (this.authenticated) {
        return {
          name: 'berufstest-section-page___de',
          params: { section: 'berufserfahrung', page: 1 }
        }
      } else {
        return {
          name: 'anmelden___de',
          query: { redirect: '/berufstest/berufserfahrung' }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.incentive {
  background-color: white;
  border: 8px solid color(light-shadow);
  background-clip: padding-box;
  border-radius: spacing(4);
  text-align: center;
  padding: spacing(4) 0;
  margin: 0 spacing(2) spacing(3);

  @include breakpoint(medium) {
    border: 12px solid color(light-shadow);
    padding: spacing(5) 0;
    margin: 0 spacing(5) spacing(5);
  }

  @include breakpoint(large) {
    max-width: spacing(160);
    padding: spacing(10) spacing(10) spacing(8);
    margin: 0 spacing(4) spacing(12);
  }

  &__title {
    @include headline-2;

    max-width: spacing(40);
    margin: auto;
    margin-bottom: spacing(2);
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
      max-width: spacing(57);
      margin-bottom: spacing(1);
    }

    @include breakpoint(large) {
      max-width: spacing(60);
    }

    &--background {
      background-color: color(primary-alt, light);
      border-radius: spacing(1);
      padding: spacing(1) spacing(1) 0;
    }
  }

  &__text {
    @include body-text-large;

    margin: auto;
    margin-bottom: spacing(5);
    max-width: spacing(70);

    @include breakpoint(large) {
      margin-bottom: spacing(6);
      width: spacing(75);
    }
  }
}

.benefits {
  display: flex;
  flex-direction: column;

  @include breakpoint(large) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.benefit {
  &:not(:last-child) {
    margin-bottom: spacing(5);

    @include breakpoint(large) {
      margin-bottom: 0;
      margin-right: spacing(4);
    }
  }

  text-decoration: none;
  color: inherit;

  &-icon {
    margin: 0 auto spacing(4);
    height: 216px;
    width: 238px;
    padding: 0;
    color: color(primary-alt, lighter);

    &:hover {
      color: color(primary-alt, base);
    }
  }

  &-title {
    @include headline-5;

    margin: 0 spacing(6) typo-aware-spacing(1);

    @include breakpoint(large) {
      margin: 0;
      margin-bottom: spacing(1);
    }
  }

  &-text {
    margin: 0 spacing(6);

    @include breakpoint(medium) {
      margin: 0 spacing(20);
    }
    @include breakpoint(large) {
      margin: 0 auto;
      max-width: 340px;
    }
  }
}
</style>
