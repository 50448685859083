<template>
  <div class="jobs">
    <div class="jobs__picture" />
    <div class="jobs__content">
      <h2 class="jobs__content-title">
        <span>
          {{ $t('pages.index.jobs.title') }}
        </span>
        <span class="jobs__content-title--background">
          {{ $t('pages.index.jobs.title2') }}
        </span>
      </h2>
      <p class="jobs__content-text">
        {{ $t('pages.index.jobs.text') }}
      </p>
      <div class="jobs__content-tiles">
        <NuxtLink
          to="/jobs/eyJKTCI6WzJdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0="
        >
          <JobPill icon="eye">
            <p class="pill__text">
              {{ $t('pages.index.jobs.tiles.1') }}
            </p>
          </JobPill>
        </NuxtLink>
        <NuxtLink
          to="/jobs/eyJKTCI6WzVdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0="
        >
          <JobPill icon="megaphone">
            <p class="pill__text">
              {{ $t('pages.index.jobs.tiles.2') }}
            </p>
          </JobPill>
        </NuxtLink>
        <NuxtLink
          to="/jobs/eyJKTCI6WzFdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0="
        >
          <JobPill icon="stairs">
            <p class="pill__text">
              {{ $t('pages.index.jobs.tiles.3') }}
            </p>
          </JobPill>
        </NuxtLink>
        <NuxtLink
          to="/jobs/eyJKTCI6WzRdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0="
        >
          <JobPill icon="flower">
            <p class="pill__text">
              {{ $t('pages.index.jobs.tiles.4') }}
            </p>
          </JobPill>
        </NuxtLink>
        <NuxtLink
          to="/jobs/eyJKTCI6WzZdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0="
        >
          <JobPill icon="flagge">
            <p class="pill__text">
              {{ $t('pages.index.jobs.tiles.5') }}
            </p>
          </JobPill>
        </NuxtLink>
        <NuxtLink
          to="/jobs/eyJKTCI6WzhdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0="
        >
          <JobPill icon="rocket">
            <p class="pill__text">
              {{ $t('pages.index.jobs.tiles.6') }}
            </p>
          </JobPill>
        </NuxtLink>
      </div>
      <NuxtLink class="jobs__content-button" to="/jobs">
        {{ $t('pages.index.jobs.button') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import JobPill from '@/components/containers/JobPill.vue'

export default {
  name: 'JobCategories',
  components: { JobPill }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: color(black, base);
}

.jobs {
  background-color: $_light-blue;
  border-radius: spacing(2);
  margin: spacing(2);
  padding: spacing(2);

  @include breakpoint(medium) {
    margin: spacing(4);
    padding: spacing(4);
  }

  @include breakpoint(large) {
    display: flex;
    width: 100%;
    max-width: spacing(160);
    padding: spacing(10);
    margin: 0 spacing(4) spacing(10);
  }

  &__picture {
    border-radius: 8px;
    height: 270px;
    margin-bottom: spacing(3);
    background: url('~/assets/images/home/woman-s.jpg') no-repeat top center;
    background-size: cover;

    @include breakpoint(medium) {
      margin-bottom: spacing(5);
      min-height: 300px;
      background-image: url('~/assets/images/home/woman-m.jpg');
    }

    @include breakpoint(large) {
      min-height: 515px;
      min-width: 459px;
      background-image: url('~/assets/images/home/woman-l.jpg');
      margin-bottom: 0;
      height: auto;
    }
  }

  &__content {
    justify-content: center;
    text-align: center;

    @include breakpoint(large) {
      text-align: left;
    }

    &-title {
      @include headline-2;

      margin-bottom: typo-aware-spacing(3);

      @include breakpoint(medium) {
        margin-bottom: typo-aware-spacing(2);
      }

      @include breakpoint(large) {
        margin-bottom: spacing(1);
        margin-left: spacing(2);
      }

      &--background {
        background-color: $_light-blue-grey;
        border-radius: spacing(1);
        padding: spacing(1) spacing(1) 0;
      }
    }

    &-text {
      @include body-text-large;

      margin: 0 auto typo-aware-spacing(4);

      @include breakpoint(medium) {
        margin-bottom: typo-aware-spacing(3);
        width: 400px;
      }

      @include breakpoint(large) {
        width: 100%;
        margin: 0 spacing(2) spacing(4);
      }
    }

    &-button {
      @include primary-button;

      margin: spacing(3) auto auto;
      width: 100%;

      @include breakpoint(medium) {
        padding: spacing(2) spacing(7);
        width: auto;
        margin: spacing(3) spacing(2) auto;
      }

      @include breakpoint(large) {
        margin: spacing(4) spacing(2) auto;
      }
    }

    &-tiles {
      text-decoration: none;
      display: flex;
      flex-direction: column;

      @include breakpoint(medium) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      @include breakpoint(large) {
        justify-content: left;
      }
    }
  }
}
</style>
