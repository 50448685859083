<template>
  <div class="job-areas">
    <h2 class="job-areas-title">
      {{ $t('pages.index.jobAreas.title') }}
    </h2>
    <p class="job-areas-text">
      {{ $t('pages.index.jobAreas.text') }}
    </p>
    <NuxtLink class="job-areas-button" to="/jobs">
      {{ $t('pages.index.jobAreas.button') }}
    </NuxtLink>
    <div class="job-areas__tiles">
      <div
        v-for="item in areas"
        :key="item.name"
        class="job-areas__tiles--tile"
      >
        <NuxtLink class="job-areas__tiles--tile-link" :to="item.link">
          <div
            class="job-areas__tiles--tile-background"
            :style="{
              background: `linear-gradient(0deg, rgba(37, 37, 37, 0.45) 9.48%, rgba(0, 0, 0, 0) 40.4%), url(${item.image})`,
              backgroundSize: 'cover'
            }"
          >
            <div class="job-areas__tiles--tile-button" :to="item.link">
              {{ $t(item.name) }}
            </div>
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobAreas',
  data() {
    return {
      areas: [
        {
          name: 'pages.index.jobAreas.areas.1',
          link: '/jobs/eyJKRiI6WzI5XSwiVyI6eyJJIjp0cnVlLCJQIjoiMTAiLCJTIjpbXX19',
          image: require('@/assets/images/home/marketing-jobs.jpg')
        },
        {
          name: 'pages.index.jobAreas.areas.2',
          link: '/jobs/eyJKRiI6WzFdLCJXIjp7IkkiOnRydWUsIlAiOiIxMCIsIlMiOltdfX0=',
          image: require('@/assets/images/home/buero-jobs.jpg')
        },
        {
          name: 'pages.index.jobAreas.areas.3',
          link: '/jobs/eyJKRiI6WzIxLDIyLDI0LDIzXSwiVyI6eyJJIjp0cnVlLCJQIjoiMTAiLCJTIjpbXX19',
          image: require('@/assets/images/home/it-jobs.jpg')
        },
        {
          name: 'pages.index.jobAreas.areas.4',
          link: '/jobs/eyJKRiI6WzM0XSwiVyI6eyJJIjp0cnVlLCJQIjoiMTAiLCJTIjpbXX19',
          image: require('@/assets/images/home/hr-jobs.jpg')
        },
        {
          name: 'pages.index.jobAreas.areas.5',
          link: '/jobs/eyJKRiI6WzUwXSwiVyI6eyJJIjp0cnVlLCJQIjoiMTAiLCJTIjpbXX19',
          image: require('@/assets/images/home/vertriebs-jobs.jpg')
        },
        {
          name: 'pages.index.jobAreas.areas.6',
          link: '/jobs/eyJKRiI6WzI3XSwiVyI6eyJJIjp0cnVlLCJQIjoiMTAiLCJTIjpbXX19',
          image: require('@/assets/images/home/logistik-jobs.jpg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.job-areas {
  width: 100%;
  margin: spacing(3) spacing(2);
  border-radius: spacing(2);
  padding: spacing(5) spacing(4) spacing(4) spacing(4);
  text-align: center;
  background-image: url('~assets/images/home/job-area-background-s.jpg');

  @include breakpoint(medium) {
    margin: spacing(5) spacing(4);
    padding: spacing(4);
    background-image: url('~assets/images/home/job-area-background-m.jpg');
  }

  @include breakpoint(large) {
    max-width: spacing(160);
    margin: 0 spacing(4) spacing(10);
    padding: spacing(10);
    background-image: url('~assets/images/home/job-area-background-l.jpg');
  }

  &-title {
    @include headline-2;

    color: white;
    margin-bottom: spacing(1);
  }

  &-text {
    @include body-text-large;

    color: white;
    margin: 0 auto spacing(3);
    padding: 0 spacing(5);

    @include breakpoint(medium) {
      width: 450px;
      margin-bottom: spacing(3);
      padding: 0;
    }

    @include breakpoint(large) {
      width: 100%;
      margin-bottom: spacing(4);
      padding: 0;
    }
  }

  &-button {
    @include white-button-with-border;

    border-color: white;
    background-color: transparent;
    color: white;
    margin: 0 auto spacing(5);
    padding-right: spacing(7);
    padding-left: spacing(7);

    @include breakpoint(large) {
      margin-bottom: spacing(6);
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &__tiles {
    display: grid;
    grid-template-columns: 1fr;
    gap: spacing(2) spacing(2);

    @include breakpoint(medium) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--tile {
      width: 100%;

      &-link {
        text-decoration: none;
      }

      &-background {
        margin-bottom: spacing(2);
        border-radius: spacing(1);
        display: flex;
        justify-content: center;

        // emulates aspect-ratio: 1.2; in older browsers
        &::before {
          float: left;
          padding-top: 1 / 1.2 * 100%;
          content: '';
        }

        &::after {
          display: block;
          content: '';
          clear: both;
        }

        @include breakpoint(medium) {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-button {
        @include white-button-with-border;

        padding-right: spacing(5);
        padding-left: spacing(5);
        border: 2px solid color(gray, base);
        align-self: flex-end;
        margin-bottom: spacing(4);

        &:hover {
          background-color: color(porcelain-light);
          color: color(black, base);
        }
      }
    }
  }
}
</style>
