<template>
  <div class="pill">
    <icon :name="icon" class="pill__icon" />
    <slot />
    <icon name="dropdown-open-indicator" class="pill__cta" />
  </div>
</template>

<script>
export default {
  name: 'JobPill',
  props: {
    icon: { type: String, default: null }
  }
}
</script>

<style lang="scss" scoped>
.pill {
  margin-top: spacing(1);
  background-color: white;
  border: 2px solid color(gray, base);
  border-radius: 8px;
  height: spacing(11);
  display: flex;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  &:hover {
    background-color: color(porcelain-light);
  }

  @include breakpoint(medium) {
    margin: spacing(2) 0 auto spacing(2);
  }

  &__icon {
    width: spacing(6);
    height: spacing(6);
    margin: auto spacing(2) auto spacing(3);
    color: color(black, base);

    @include breakpoint(medium) {
      width: spacing(5);
      height: spacing(5);
      margin: auto spacing(2);
    }
    @include breakpoint(large) {
      width: spacing(5);
      height: spacing(5);
      margin: auto spacing(1) auto spacing(2);
    }
  }

  &__text {
    @include headline-6;

    color: black;
    margin: auto;
    margin-left: 0;
    max-width: 170px;
    text-align: left;

    @include breakpoint(medium) {
      width: auto;
      max-width: 170px;
    }

    @include breakpoint(large) {
      width: auto;
      max-width: 100%;
    }
  }

  &__cta {
    transform: rotate(270deg);
    height: spacing(2);
    width: spacing(2);
    margin: auto spacing(3);

    @include breakpoint(large) {
      margin-left: spacing(2);
      margin-right: spacing(4);
    }
  }
}
</style>
