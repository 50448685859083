<template>
  <div class="container">
    <section class="hero">
      <div class="hero__content">
        <h1 class="hero__title">
          {{ $t('pages.index.hero.title') }}
        </h1>
        <p class="hero__text">
          {{ $t('pages.index.hero.text') }}
        </p>
        <NuxtLink class="hero__button" :to="link">
          {{ $t('pages.index.hero.startQuiz') }}
        </NuxtLink>
      </div>
    </section>
    <section>
      <Incentive />
    </section>
    <section>
      <JobCategories />
    </section>
    <section>
      <JobAreas />
    </section>
    <section>
      <QAndA />
    </section>
    <section>
      <SeoLinks />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JobCategories from '@/components/index/JobCategories.vue'
import JobAreas from '@/components/index/JobAreas.vue'
import SeoLinks from '@/components/index/SeoLinks.vue'
import QAndA from '~/components/index/QAndA.vue'
import WebtrekkMixin from '@/components/tracking/webtrekk-mixin.js'
import richSnippet from '@/lib/data/rich-snippet.json'
import Incentive from '~/components/index/Incentive.vue'

export default {
  components: { Incentive, JobCategories, JobAreas, QAndA, SeoLinks },
  mixins: [WebtrekkMixin],
  middleware: [
    'load-user',
    function quizCompletenessRedirect({ store, redirect, route }) {
      if (store.getters['user/email']) {
        return store.getters['user/isJobmailReady']
          ? redirect({ path: '/jobs', query: route.query })
          : redirect({ path: '/berufstest', query: route.query })
      }
    }
  ],
  data() {
    return {
      webtrekk: {
        pageType: 'startseite',
        cluster: 'bot'
      },
      canonicalRoute: `${this.$config.PRODUCTION_URL}${this.$nuxt.$route.fullPath}`
    }
  },
  head() {
    return {
      title: 'Welcher Beruf passt zu mir? - Der Berufstest von ZEIT Campus',
      link: [{ rel: 'canonical', href: this.canonicalRoute }],
      script: [{ json: richSnippet, type: 'application/ld+json' }],
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content:
            'index,follow,noarchive,max-snippet:-1,max-image-preview:large,max-video-preview:-1'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['email']),
    authenticated() {
      return this.email !== ''
    },
    link() {
      if (this.authenticated) {
        return {
          name: 'berufstest-section-page___de',
          params: { section: 'berufserfahrung', page: 1 }
        }
      } else {
        return {
          name: 'anmelden___de',
          query: { redirect: '/berufstest/berufserfahrung' }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  display: flex;
  justify-content: center;
}

.container {
  background-color: color(gray, lightest);
  padding-bottom: spacing(3);
}

.hero {
  $hero-blue: color(tertiary-alt);
  $hero-transparent: rgba($hero-blue, 0);

  border-bottom-right-radius: spacing(2);
  border-bottom-left-radius: spacing(2);
  background: linear-gradient(
      110.84deg,
      $hero-blue 16.61%,
      $hero-transparent 56.19%
    ),
    linear-gradient(8.53deg, $hero-blue 30.17%, $hero-transparent 70.11%),
    url('~assets/images/home/hero-s.jpg') no-repeat top center/cover, $hero-blue;
  background-blend-mode: normal, normal, multiply, normal;
  padding: 0 0 320px 0;
  margin-bottom: -320px;

  @include breakpoint(medium) {
    background: linear-gradient(
        97.95deg,
        $hero-blue 27.39%,
        $hero-transparent 72%
      ),
      linear-gradient(360deg, $hero-blue 0%, $hero-transparent 68.59%),
      url('~assets/images/home/hero-m.jpg') no-repeat top center/cover,
      $hero-blue;
    background-blend-mode: normal, normal, multiply, normal;
    text-align: left;
    padding: 0 spacing(5) 624px;
    margin-bottom: -624px;
  }

  @include breakpoint(large) {
    background: linear-gradient(
        360deg,
        $hero-blue 21.4%,
        $hero-transparent 62.78%
      ),
      url('~assets/images/home/hero-l.png') no-repeat right center/cover,
      $hero-blue;
    background-blend-mode: normal, normal, multiply, normal;
    padding: 0 spacing(2) 240px;
    margin-bottom: -240px;
  }

  &__content {
    width: 100%;

    @include breakpoint(large) {
      max-width: spacing(160);
      margin: 0 spacing(4);
    }
  }

  &__title,
  &__text {
    text-align: left;
  }

  &__title {
    @include headline-3-special-big;

    color: color(white);
    line-height: 1;
    padding: spacing(8) spacing(10) 0 spacing(3);
    margin-bottom: spacing(6);

    @include breakpoint(small only) {
      margin-bottom: typo-aware-spacing(3);
    }

    @include breakpoint(medium) {
      @include headline-2-special-big;

      padding: spacing(8) 0 0;
      margin-bottom: typo-aware-spacing(3);
      max-width: 400px;
    }

    @include breakpoint(large) {
      @include headline-1-special-big;

      padding-top: spacing(10);
      margin-bottom: typo-aware-spacing(4);
      max-width: spacing(72);
    }
  }

  &__text {
    color: color(white);
    margin-bottom: typo-aware-spacing(4);
    padding: 0 spacing(10) 0 spacing(3);

    @include breakpoint(medium) {
      @include body-text-large;

      padding: 0;
      max-width: 360px;
    }

    @include breakpoint(large) {
      @include body-text-large;

      max-width: spacing(68);
    }
  }

  &__button {
    @include primary-button;

    padding: spacing(2) spacing(7);
    margin-left: spacing(3);
    margin-bottom: spacing(4);

    @include breakpoint(medium) {
      margin-bottom: spacing(5);
      margin-left: 0;
    }

    @include breakpoint(large) {
      margin-bottom: spacing(10);
    }
  }
}
</style>
