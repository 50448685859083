<template>
  <div class="questions">
    <h2 class="questions-title">
      {{ $t('pages.index.questions.title') }}
    </h2>
    <h6>Wie funktioniert BOA?</h6>
    <p>
      Bist du auf der <strong>Suche nach deinem nächsten Job</strong>, aber noch
      unsicher, wohin die Reise geht? Wenn du deine Zukunft planst, willst du
      natürlich die richtige Entscheidung treffen. BOA
      („<strong>B</strong>erufs<strong>o</strong>rientierungs<strong>a</strong>ngebot")
      hilft dir deine persönlichen Stärken zu ermitteln und den passenden Job zu
      finden. Wie das geht? Nach der Registrierung beantwortest du
      <strong>72&nbsp;schnelle Fragen zu deiner Persönlichkeit</strong>
      und teilst uns deine Jobwünsche mit. Das dauert nicht mal
      <strong>15&nbsp;Minuten</strong> und du bekommst direkt im Anschluss dein
      individuelles Testergebnis. Darin erfährst du, wo deine beruflichen
      Stärken liegen und erhältst passende Jobvorschläge. Der Test wurde nach
      <strong>wissenschaftlichen Standards</strong> entwickelt und wird dadurch
      dem Anspruch gerecht, dich gut zu beraten.
    </p>
    <h6>Welche Vorteile bietet der Berufstest von ZEIT Campus?</h6>
    <p>
      Natürlich hast du keine Lust tausende Stellenanzeigen zu durchforsten.
      Dafür gibt es die Jobmail von BOA! Der Service übernimmt für dich die
      Suche nach passenden Jobs. Nach Abschluss des Berufstests werden deine
      Profildaten mit über
      <strong>100.000&nbsp;Stellenausschreibungen</strong> abgeglichen und wir
      schlagen dir automatisiert die Jobs vor, die perfekt zu dir passen.
      <strong>Du entscheidest</strong> dabei, wie oft du Jobs per Mail erhalten
      möchtest oder ob du unsere Plattform direkt besuchst. Darüber hinaus
      erhältst du exklusive Einladungen zu Online Sessions und Events rund um
      die Themen Berufseinstieg und Karriere. Unser Angebot ist dabei jederzeit
      <strong>vollständig anonym und natürlich kostenfrei.</strong>
    </p>
    <NuxtLink class="questions-button" :to="{ name: 'anmelden___de' }">
      {{ $t('pages.index.questions.button1') }}
    </NuxtLink>
    <h6>Was sind berufliche Stärken und warum sind diese so wichtig?</h6>
    <p>
      Unterschiedliche Berufsbilder erfordern
      <strong>unterschiedliche Persönlichkeiten</strong>. Würdest du nur über
      deine fachliche Qualifikation definiert werden, gäbe es eine Menge anderer
      Wettbewerber, von denen du dich gar nicht unterscheidest. Es geht aber
      häufig auch darum, wie du mit deinen Persönlichkeitsmerkmalen
      <strong>in ein Unternehmen oder Team passt.</strong>
    </p>
    <h6>Was ist ein Persönlichkeitsprofil?</h6>
    <p>
      Nachdem du den Berufstest gemacht hast, erhältst du ein
      <strong>detailliertes Persönlichkeitsprofil.</strong> Anhand deiner
      Antworten erfährst du, welche Fähigkeiten dich auszeichnen und ob diese
      überdurchschnittlich ausgeprägt sind. Daraus ergeben sich acht
      Persönlichkeitsdimensionen:
    </p>
    <ol>
      <li>
        <strong>Leistungsmotivation:</strong> Bist du ehrgeizig und willst
        bisherige Leistungen immer übertreffen?
      </li>
      <li>
        <strong>Führungsmotivation:</strong> Gibst du gerne den Ton an und
        verteilst Aufgaben?
      </li>
      <li>
        <strong>Teamorientierung und soziale Verträglichkeit:</strong> Kommst du
        gut mit anderen aus?
      </li>
      <li>
        <strong>Durchsetzungs- und Konfliktfähigkeit:</strong> Kannst du dich in
        schwierigen Situationen gut durchsetzen?
      </li>
      <li>
        <strong>Kontaktfähigkeit und Aufgeschlossenheit:</strong> Fällt es dir
        leicht, auf andere zuzugehen und zu netzwerken?
      </li>
      <li>
        <strong>Ideenreichtum und Gestaltungsfreude:</strong> Entwickelst du
        Ideen, auf die sonst keiner kommt?
      </li>
      <li>
        <strong>Organisationsfähigkeit und Gewissenhaftigkeit:</strong> Du bist
        strukturiert und setzt deine Aufgaben möglichst präzise und genau um?
      </li>
      <li>
        <strong>Stressbewältigung und Anpassungsfähigkeit:</strong> Blühst du
        eher auf oder hebeln dich herausfordernde Situationen aus?
      </li>
    </ol>
    <h6>Welche Jobs finde ich bei BOA?</h6>
    <p>
      Je nachdem in welcher Phase du dich gerade befindest - bei BOA findest du:
    </p>
    <ol>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzFdLCJDIjo0LCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Berufseinsteiger Positionen oder Absolventen Jobs</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzRdLCJDIjoxLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Freie Ausbildungsplätze</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzhdLCJDIjoxLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Praktikumsplätze</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzVdLCJDIjoyLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Werkstudentenstellen oder Jobs als Studentische Hilfskraft</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzZdLCJDIjoyLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Trainee-Stellen</NuxtLink
        >
        oder
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzddLCJDIjoyLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >ein Volontariat</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzNdLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Positionen als wissenschaftlicher Mitarbeiter</NuxtLink
        >
      </li>
      <li>
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzFdLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Junior</NuxtLink
        >
        oder
        <NuxtLink
          class="text-link"
          to="/jobs/eyJKTCI6WzJdLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
          >Midlevel und Senior</NuxtLink
        >
        Positionen
      </li>
    </ol>
    <p>
      Für jede Suche bekommst du eine zu dir
      <strong>passende Auswahl an Jobs.</strong>
    </p>
    <NuxtLink class="questions-button" to="/jobs">
      {{ $t('pages.index.questions.button2') }}
    </NuxtLink>
    <h6>Kann man über BOA auch die passende Ausbildungsstelle finden?</h6>
    <p>
      Wenn du wissen möchtest, welche
      <NuxtLink
        class="text-link"
        to="/jobs/eyJKTCI6WzRdLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
        >Ausbildungsstellen</NuxtLink
      >
      gut zu dir und deinem Profil passen, bietet dir der Berufswahltest
      <strong
        >Orientierung und Einblicke in eine Vielzahl passender
        Ausbildungsplätze.</strong
      >
      Auch wenn du vor der Entscheidung stehst,
      <strong>Studium oder Ausbildung</strong>, kann BOA dir bei dieser
      Entscheidung weiterhelfen.
    </p>
    <NuxtLink
      class="questions-button"
      to="/jobs/eyJKTCI6WzRdLCJXIjp7IkkiOmZhbHNlLCJQIjoxMCwiUyI6W119fQ=="
    >
      {{ $t('pages.index.questions.button3') }}
    </NuxtLink>
  </div>
</template>

<script>
export default {
  name: 'QAndA'
}
</script>

<style lang="scss" scoped>
.questions {
  margin: spacing(2);
  padding: spacing(2);
  background-color: white;
  border-radius: spacing(2);

  @include breakpoint(medium) {
    margin: spacing(4);
    padding: spacing(4);
  }

  @include breakpoint(large) {
    width: 100%;
    max-width: spacing(160);
    padding: spacing(10);
    margin: 0 spacing(4) spacing(8);
  }

  &-title {
    @include headline-2;
  }

  &-button {
    @include tertiary-button;

    background-color: color(black, base);
    color: white;
    margin-top: spacing(2);
    padding: spacing(1.5) spacing(5) spacing(1.5) spacing(5);

    &:hover {
      background-color: $_light-black;
      border-color: $_light-black;
    }
  }

  ol {
    @include body-text;

    list-style: none;
    margin: spacing(4) 0 spacing(4);

    li {
      position: relative;
      margin-bottom: typo-aware-spacing(3);
      margin-right: spacing(2);

      &::before {
        content: '';
        position: absolute;
        left: -1.5rem;
        display: block;
        height: 18px;
        width: 13px;
        background: url('~/assets/sprite/svg/list-style-star.svg') no-repeat
          left 2px/13px 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h6 {
    margin: spacing(5) 0 spacing(2);
  }

  .text-link {
    color: color(black, base);
  }
}
</style>
