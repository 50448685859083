import seoConfig from '@/lib/data/seo-config.json'
import { emptySearchFilters } from '~/store/job.js'
import { jobFilterToCode } from '~/lib/util/encrypt-filters.js'

export function meta(searchCode, sentry = null) {
  const config = SEO_MAPPING[searchCode]
  checkConfig(config, sentry)
  if (!config) {
    return [
      {
        hid: 'robots',
        name: 'robots',
        content: 'noindex,nofollow'
      }
    ]
  }

  return [
    {
      hid: 'robots',
      name: 'robots',
      content:
        'index,follow,noarchive,max-snippet:-1,max-image-preview:large,max-video-preview:-1'
    },
    {
      hid: 'description',
      name: 'description',
      content: config.description
    }
  ]
}

export function title(searchCode, sentry = null) {
  const config = SEO_MAPPING[searchCode]
  checkConfig(config, sentry)
  if (!config) {
    return {}
  }

  return { filter: {}, title: config.title }
}

export function headerAddendum(searchCode, sentry = null) {
  const config = SEO_MAPPING[searchCode]
  checkConfig(config, sentry)
  return (config || {}).headerAddendum
}

export function links(sentry = null) {
  return Object.entries(SEO_MAPPING).map(([code, config]) => {
    return {
      url: `/jobs/${config.computedCode}`,
      title: config.title,
      text: config.headerAddendum
    }
  })
}

const checkConfig = (config, sentry) => {
  if (!config || config.computedCode === config.sitemapCode) {
    return
  }

  const error = new Error(
    `SEO: mismatch between sitemap code and computed code: ${JSON.stringify(
      config
    )}`
  )
  // eslint-disable-next-line no-console
  console.error(error)
  if (sentry) {
    sentry.captureException(error)
  }
}

const SEO_MAPPING = seoConfig.reduce((acc, config) => {
  const code = jobFilterToCode({ ...emptySearchFilters, ...config.filter })
  acc[code] = { ...config, computedCode: code }

  return acc
}, {})
