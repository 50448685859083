import { render, staticRenderFns } from "./SeoLinks.vue?vue&type=template&id=f90865da&scoped=true"
import script from "./SeoLinks.vue?vue&type=script&lang=js"
export * from "./SeoLinks.vue?vue&type=script&lang=js"
import style0 from "./SeoLinks.vue?vue&type=style&index=0&id=f90865da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../base_image/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f90865da",
  null
  
)

export default component.exports